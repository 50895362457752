import { defineStore } from 'pinia';


interface SessionInterface {
  email: string,
  token: string,
}

export const useSessionStore = defineStore({
  id: 'session',
  persist: true,
  state: (): SessionInterface => ({
    email: '',
    token: '',
  }),
  getters: {
    hasSession: (state) => !!(state.email && state.token),
  },
  actions: {
    createSession({ email, token }: SessionInterface) {
      this.$patch({ email, token });
    },
    destroySession() {
      this.$reset();
    },
  },
});
