import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBBg6nbGMqH5L6v2TeknknJTEgWYBu9acc',
  authDomain: 'ecx-flutter.firebaseapp.com',
  projectId: 'ecx-flutter',
  storageBucket: 'ecx-flutter.appspot.com',
  messagingSenderId: '615870219916',
  appId: '1:615870219916:web:4dacb37493d25276909da9',
  measurementId: 'G-PCHQH37EFY',
};

export const firebase = initializeApp(firebaseConfig);
