import { useScriptTag } from '@vueuse/core';

const RECAPTCHA_URI = import.meta.env.VITE_RECAPTCHA_URI;

export const recaptcha = {
  install() {
    useScriptTag(
      RECAPTCHA_URI,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      { async: false },
    );
  },
};
