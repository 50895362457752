import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from './router';
import { i18n } from './plugins/i18n';
import { firebase } from './plugins/firebase';
import { maska } from 'maska';
import 'virtual:fonts.css';
import { getAnalytics } from 'firebase/analytics';
import { recaptcha } from './plugins/recaptcha';
import { blipChat } from './plugins/blipChat';
import VueApexCharts from 'vue3-apexcharts';
import ApexCharts from 'apexcharts';

const app = createApp(App);
const pinia = createPinia();
getAnalytics(firebase);

pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(recaptcha);
app.use(VueApexCharts);
app.use(blipChat as never, { router });
app.directive('maska', maska);

app.mount('#app');

app.config.globalProperties.$apexcharts = ApexCharts;

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $apexcharts: typeof ApexCharts;
  }
}

