
import { createI18n } from 'vue-i18n';

import ptBR from '../locales/pt-BR.json';
import numberFormats from '../locales/numberFormats.json';

type MessageSchema = typeof ptBR;

type NumberSchema = typeof numberFormats;

export const i18n = createI18n<[MessageSchema, NumberSchema], 'pt-BR'>({
  locale: 'pt-BR',
  legacy: false,
  messages: {
    'pt-BR': ptBR,
  },
  numberFormats: {
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
      },
    },
  },
});

export const i18nGlobalInstance = i18n.global;
